import React from "react"
import _ from "lodash";

import facebookIcon from '../icons/facebook_ikona.svg';
import instagramIcon from '../icons/insta_ikona.svg';
import logo from '../slider/logo.png';
import { useIntl } from 'gatsby-plugin-intl';

export default () => {
    const intl = useIntl();
    return (
        <div className="footer-container">
            <div className="footer">

                <img className="brand" src={logo}/>

                <div className="footer-row">
                    <div className="footer-text">
                        <div className="text">
                            {intl.formatMessage({ id: "contactPage.address.line1" })}<br/>
                            {intl.formatMessage({ id: "contactPage.address.line2" })}<br/>
                            {intl.formatMessage({ id: "contactPage.address.line3" })}
                        </div>
                    </div>
                    <div className="footer-text desktop">
                        <div className="text">
                            {intl.formatMessage({ id: "contactPage.telephoneNumber" })}<br/>
                            {intl.formatMessage({ id: "contactPage.faxNumber" })}
                            {
                                !!_.trim(intl.formatMessage({ id: "contactPage.faxNumber" })) && <br/>
                            }
                            <br/>
                            info@nanonet.hr<br/>
                        </div>
                    </div>
                    <div className="footer-text desktop">
                        <div className="text">
                            08:00 - 18:00<br/>
                            {intl.formatMessage({ id: "contactPage.workingDays" })}
                        </div>
                    </div>
                    <div className="footer-text desktop large"/>
                    <div className="footer-text footer-social">
                        <div className="text">
                            {intl.formatMessage({ id: "footer.social" })}
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/pages/Nanonet/652981478063562?fref=ts" target="_blank">
                                <img alt="facebook" src={facebookIcon}/>
                            </a>
                            <a className="insta" href="https://www.instagram.com/nanonet_interior/" target="_blank">
                                <img alt="instagram" src={instagramIcon}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="copyright text">
                    <div style={{flex: 1}}>© Nanonet d.o.o. 2021</div>
                    <div>{intl.formatMessage({ id: "footer.copyright" })}</div>
                </div>
            </div>
        </div>
    );
}
