import React, { useState, useEffect, useRef } from 'react'
import { useIntl, Link, changeLocale } from 'gatsby-plugin-intl';

import menuIcon from '../icons/menu.svg';

import logo from '../slider/logo.png';

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default (props) => {
    const intl = useIntl();

    const [navPickerOpen, setNavPickerOpen] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setNavPickerOpen(false));

    return (
        <div className="header-container">
            <div className="header">
                <Link to="/">
                    <img alt="" className="brand" src={logo}/>
                </Link>

                <div className={'button-link'  + (props.lang === 'hr' ? '' : ' disabled')} onClick={() => changeLocale('hr')}>
                    <div className="link text lang" style={{marginRight: 20}}>HR</div>
                </div>
                <div className="button-link">
                    <div className="link text lang" style={{marginLeft: '0', marginRight: '0'}}>|</div>
                </div>
                <div className={'button-link'  + (props.lang === 'de' ? '' : ' disabled')} onClick={() => changeLocale('de')}>
                    <div className="link text lang">DE</div>
                </div>

                <span className="navigation-container">
                    <Link activeClassName="active" className="link text" to="/contact/">{intl.formatMessage({ id: "header.contact" })}</Link>
                    <Link activeClassName="active" className="link text" to="/services/">{intl.formatMessage({ id: "header.services" })}</Link>
                    <Link activeClassName="active" className="link text" to="/about/">{intl.formatMessage({ id: "header.about" })}</Link>
                    <Link activeClassName="active" className="link text" to="/portfolio/">{intl.formatMessage({ id: "header.projects" })}</Link>
                    <Link activeClassName="active" className="link text" to="/">{intl.formatMessage({ id: "header.home" })}</Link>
                </span>

                <div className="navigation-picker-container" ref={wrapperRef}>
                    <img src={menuIcon} alt="menu" onClick={() =>  setNavPickerOpen(!navPickerOpen)}/>
                    {
                        navPickerOpen &&
                        <div className="navigation-picker">
                            <Link onClick={() => setNavPickerOpen(false)} className="link text" to="/">{intl.formatMessage({ id: "header.home" })}</Link>
                            <Link onClick={() => setNavPickerOpen(false)} className="link text" to="/portfolio/">{intl.formatMessage({ id: "header.projects" })}</Link>
                            <Link onClick={() => setNavPickerOpen(false)} className="link text" to="/about/">{intl.formatMessage({ id: "header.about" })}</Link>
                            <Link onClick={() => setNavPickerOpen(false)} className="link text" to="/services/">{intl.formatMessage({ id: "header.services" })}</Link>
                            <Link onClick={() => setNavPickerOpen(false)} className="link text" to="/contact/">{intl.formatMessage({ id: "header.contact" })}</Link>
                            <hr />
                            <div className={'button-link'  + (props.lang === 'hr' ? '' : ' disabled')} onClick={() => {
                                changeLocale('hr');
                                setNavPickerOpen(false);
                            }}>
                                <div className="link text lang">HR</div>
                            </div>
                            <div className={'button-link'  + (props.lang === 'de' ? '' : ' disabled')} onClick={() => {
                                changeLocale('de');
                                setNavPickerOpen(false);
                            }}>
                                <div className="link text lang">DE</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
